<template>
  <layout-single>
    <template #content v-if="!!event">
      <v-container v-if="!!registrations">
        <v-row
          v-if="evaluation"
          class="ma-0 pa-0 mt-1"
        >
          <v-col class="ma-0 pa-0">
            <v-btn
              :class="[
                evaluationFilled ? 'green white--text' : 'blue white--text'
              ]"
              style="width: 100%;"
              elevation="0"
              large
              @click="redirect(`/event/${$route.params.eventId}/track-and-weather`)"
            >
              <span v-if="evaluationFilled">
                <v-icon
                  class="mr-1">mdi-check
                </v-icon>  {{ $t('Track and weather') }}
              </span>
              <span v-else>
                {{ $t('Track and weather') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider v-if="evaluation && evaluation.event" class="mt-4" />

        <v-row>
          <v-col align="center">
            <h1>{{ $t('Choose registration') }}</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>

        <v-row v-if="search !== ''">
          <v-col align="left">
            <strong>Visar {{ filteredRegistrations.length }} hundar av {{ registrations.length }}</strong>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="left">
            <v-list two-line subheader class="transparent">
              <v-list-item
                v-for="registration in filteredRegistrations"
                :key="registration.id"
                @click="redirect(`/event/${event.id}/registration/${registration.id}`)"
              >
                <v-list-item-avatar>
                  <v-img
                    :src="registration.competitor.avatar"
                    class="grey darken-4"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="registration.competitor.name" />
                  <v-list-item-subtitle
                    v-if="registration.class"
                    v-text="`${$t('Class')}: ${registration.class}`"
                  />
                  <v-list-item-subtitle
                    v-else
                    v-text="`${$t('Class')}: ${fallbackClass}`"
                  />
                  <v-list-item-subtitle
                    v-text="`${$t('Breed')}: ${registration.competitor.metadata.breed}`"
                  />
                  <v-list-item-subtitle
                    v-text="`${$t('Date')}: ${dateFormat(registration.scheduled_at)}`"
                  />
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn elevation="0" icon>
                    <v-icon color="grey lighten-1">
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

      </v-container>
    </template>
  </layout-single>
</template>

<script>
import LayoutSingle from '@/components/layout-single'

import moment from 'moment';

export default {
  name: 'EventShow',

  components: {
    LayoutSingle
  },

  data: () => ({
    moment,
    search: '',
  }),

  computed: {
    filteredRegistrations() {
      if (this.search === '') {
        return this.registrations;
      }

      const search = this.search.toLowerCase();

      return this.registrations.filter((r) => (
        [
          r.competitor.name.toLowerCase().includes(search),
          r.competitor.metadata.breed.toLowerCase().includes(search),
          r.competitor.metadata.regId.toLowerCase().includes(search),
          // e.categories.map((c) => c.value.toLowerCase()).some((s) => s.includes(search)),
        ].some((s) => s === true)
      ))
    },

    registrations () {
      return this.$store.state.rx.registrations.filter(
        (r) => r.eventId === this.$route.params.eventId
      ).sort((a, b) => a.scheduled_at - b.scheduled_at)
    },

    fallbackClass () {
      const classes = this.event.categories.find((c) => c.type === 'Classes');
      return classes ? classes.value : 'Unknown';
    },

    event () {
      return this.$store.state.rx.events.find(
        (e) => e.id === this.$route.params.eventId
      )
    },

    test () {
      return this.$store.state.rx.tests.find(
        t => t.type === this.event.type
      )
    },

    evaluation () {
      return this.test && this.test.evaluation.event && this.event.results
    },

    evaluationFilled () {
      return this.test.evaluation.event.length === this.event.results.length
    }
  },

  mounted () {
    if (!this.event) {
        this.$router.push('/event').catch(() => {})
    }

    this.$store.commit('setNavigation', {
      show: true,
      to: '/event',
      title: 'Registrations'
    })
  },

  methods: {
    dateFormat(current) {
      const format = Number(moment(current).utc().format('Hmm')) === 0 ? 'll' : 'lll';
      return moment(current).locale('sv').format(format);
    }
}
}
</script>
